import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et">
      <ol>
        <li>
          Tarbijakampaania „Lay’s Music 2024“ on Eestis toimuv kampaania, mida
          korraldab Lay’s krõpsude edasimüüja Eestis – PepsiCo Eesti AS
          (edaspidi kampaania korraldaja), registrinumber 10438708, Tuglase 14,
          51006 Tartu. Loosimist korraldab Direct Messenger OÜ, registrinumber
          11981389, Türi 10C, 11313 Tallinn.
        </li>
        <li>
          Kampaania territoorium – kõik kauplused Eesti Vabariigi
          territooriumil, kus kampaanias osalevaid tooteid müüakse.
        </li>
        <li>Kampaanias võivad osaleda igas vanuses Eesti elanikud.</li>
        <li>
          Kampaania leiab aset 28. oktoobrist kuni 22. detsembrini 2024 (k.a).
        </li>
        <li>Kampaanias osalevad kõik Lay’s tooted.</li>
        <li>
          Jaemüügis saadaolevad ja kampaanias osalevate toodete arv ei ole
          piiratud. Arvestatakse mõistliku nõudlusega. Ostes kauplustest Lay’s
          tooteid enne või pärast käesolevate tingimuste punktis 4 toodud
          kuupäevi, ei anna õigust kampaanias osaleda.
        </li>
        <li>
          Kampaanias osalemiseks peab tarbija ostma kauplusest vähemalt ühe
          Lay’s toote ja registreerima ostutšeki veebilehel www.lays.ee.{' '}
          <ol>
            <li>
              Registreerides ostutšeki veebilehel www.lays.ee tuleb ära märkida
              loosis osaleja eesnimi, perekonnanimi, ostutšeki number,
              telefoninumber ja e-posti aadress. Registreerimine veebilehel
              www.lays.ee on tasuta. Kui osalejal ei õnnestu ostutšekil olevat
              numbrit edukalt registreerida, ilmub lehele veateade.{' '}
            </li>
            <li>
              Registreerides ostutšekil oleva numbri vastavalt käesolevate
              eeskirjade punktile 7.1., võtab kampaanias osaleja vastutuse
              korrektsete isikuandmete esitamise ja nende esitamisel tehtud
              vigade eest.
            </li>
            <li className="bold">
              Kampaanias osaleja hoiab registreeritud ostutšeki alles kampaania
              lõpuni, et tõestada kampaanias osaleva toote ostmist.
            </li>
            <li>
              Ühte ostutšeki numbrit saab kogu kampaania toimumise ajal
              registreerida ühe korra.
            </li>
            <li>
              Üks ja sama osaleja võib teha mitu registreerimist, kui ostab
              kampaanias osalevaid tooteid mitu korda ja registreerib iga kord
              uue ostutšeki numbri.{' '}
              <ol>
                <li>
                  Juhul, kui üks ja sama isik üritab ühte ostutšekki mitu korda
                  registreerida, osaleb loosimises ainult esimene korrektselt
                  tehtud registreering, kõik korduvad tühistatakse.
                </li>
                <li>
                  Vaheauhinna võitnud tšekk ei osale teist korda peaauhinna
                  loosimises. Ühe tšekiga saab võita ühe korra.{' '}
                </li>
              </ol>{' '}
            </li>
            <li>
              Sama osaleja võib võita mitu korda, kui on registreeritud mitu
              erinevat ostutšekki.
            </li>
          </ol>
        </li>
        <li>
          <span className="bold">Auhinnafond ja loosimised</span>{' '}
          <ol>
            <li>Auhinnafondi koguväärtus on 14 092 €.</li>
            <li>
              Peaauhinnaks on tuntud kohaliku bändi 5MIINUST privaatkontsert
              (väärtuses kuni 10 000 €). Kampaania korraldaja loosib välja ka
              lisaauhinnad, milleks on: 40 (nelikümmend) Piletilevi kinkepiletit
              (väärtuses 50€) ja 40 (nelikümmend) kasti Lay’s Fromage 140g
              krõpse (väärtuses 52,3 €/kast).{' '}
            </li>
            <li>
              Loosimiste kuupäevad on järgnevad:{' '}
              <ol>
                <li>
                  4.11.2024 kell 12:00, mil loositakse välja viis kasti Lay’s
                  Fromage krõpsu ja viis kinkepiletit. Loosis osalevad kõik
                  tšekid, mis on registreeritud ajavahemikus 28.10.2024 kell
                  00:00:00 kuni 03.11.2024 kell 23:59:59.
                </li>
                <li>
                  11.11.2024 kell 12:00, mil loositakse välja viis kasti Lay’s
                  Fromage krõpsu ja viis kinkepiletit. Loosis osalevad kõik
                  tšekid, mis on registreeritud ajavahemikus 04.11.2024 kell
                  00:00:00 kuni 10.11.2024 kell 23:59:59.
                </li>
                <li>
                  18.11.2024 kell 12:00, mil loositakse välja viis kasti Lay’s
                  Fromage krõpsu ja viis kinkepiletit. Loosis osalevad kõik
                  tšekid, mis on registreeritud ajavahemikus 11.11.2024 kell
                  00:00:00 kuni 17.11.2024 kell 23:59:59.
                </li>
                <li>
                  25.11.2024 kell 12:00, mil loositakse välja viis kasti Lay’s
                  Fromage krõpsu ja viis kinkepiletit. Loosis osalevad kõik
                  tšekid, mis on registreeritud ajavahemikus 18.11.2024 kell
                  00:00:00 kuni 24.11.2024 kell 23:59:59.
                </li>
                <li>
                  02.12.2024 kell 12:00, mil loositakse välja viis kasti Lay’s
                  Fromage krõpsu ja viis kinkepiletit. Loosis osalevad kõik
                  tšekid, mis on registreeritud ajavahemikus 25.11.2024 kell
                  00:00:00 kuni 01.12.2024 kell 23:59:59.
                </li>
                <li>
                  9.12.2024 kell 12:00, mil loositakse välja viis kasti Lay’s
                  Fromage krõpsu ja viis kinkepiletit. Loosis osalevad kõik
                  tšekid, mis on registreeritud ajavahemikus 02.12.2024 kell
                  00:00:00 kuni 08.12.2024 kell 23:59:59.
                </li>
                <li>
                  16.12.2024 kell 12:00, mil loositakse välja viis kasti Lay’s
                  Fromage krõpsu ja viis kinkepiletit. Loosis osalevad kõik
                  tšekid, mis on registreeritud ajavahemikus 09.12.2024 kell
                  00:00:00 kuni 15.12.2024 kell 23:59:59.
                </li>
                <li>
                  23.12.2024 kell 12:00, mil loositakse välja viis kasti Lay’s
                  Fromage krõpsu ja viis kinkepiletit. Loosis osalevad kõik
                  tšekid, mis on registreeritud ajavahemikus 16.12.2024 kell
                  00:00:00 kuni 22.12.2024 kell 23:59:59.
                </li>
                <li>
                  Peaauhind loositakse välja 23.12.2024 ja välja loosimisel
                  arvestatakse kõiki perioodil 28.10.2024 kell 00:00:00 kuni
                  22.12.2024 kell 23:59:59 registreeritud tšekke (v.a
                  vaheauhindade võidu tšekid). Peaauhinnaks on eksklusiivne
                  5MIINUST erakontsert.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <span className="bold">Peaauhind</span>
          <ol>
            <li>
              Lay’s Music kampaania peaauhinnaks on 5MIINUST (edaspidi artist)
              eksklusiivne erakontsert.{' '}
            </li>
            <li>
              Kontsert peab toimuma{' '}
              <span className="bold">ühe aasta jooksul</span> võitja
              väljakuulutamise päevast (täpne periood kontserdi toimumiseks on
              ajavahemikus 01.02.2025 kuni 31.12.2025). Kontserdi kuupäeva
              valimise ja kontserdi toimumise vahel peab olema vähemalt 1 (üks)
              kuu etteteavitamise periood.{' '}
            </li>
            <li>
              Kontserdi kuupäev lepitakse kokku artisti ja võitja vaheliste
              läbirääkimiste tulemusena. Mõlemal poolel on õigus pakkuda välja 3
              (kolm) sobivat kontserdi kuupäeva ja õigus keelduda 2 (kaks) korda
              teise lepinguosalise pakutud kuupäevast. Kui võitja on pakkunud
              välja 3 (kolm) kuupäeva, peab artist vastu võtma vähemalt 1 (ühe)
              neist.
            </li>
            <li>
              Võitja pakutud kuupäevad ei tohi olla kõik ühe kalendrikuu piires.
            </li>
            <li>
              Peaauhinna sisse kuulub järgnev:{' '}
              <ul>
                <li>miinimum tunnine kontsert artistilt;</li>
                <li>
                  kõik kontserdi toimumiseks vajalik tehniline ja muu tugi
                  (valgustus, tehnika, instrumendid);
                </li>
                <li>
                  artisti transport kontserdi toimumispaika ja hiljem sealt ära;
                </li>
                <li>Lay’s tooted.</li>
              </ul>
            </li>
            <li>
              Kõige muu eest, mis punkt 9.3. alla ei kuulu, tuleb peaauhinna
              võitjal endal hoolt kanda. Näiteks:{' '}
              <ul>
                <li>kontserdipaik;</li>
                <li>kontserdikülalised;</li>
                <li>kontserdi turvalisus;</li>
                <li>toitlustus;</li>
                <li>muu, mida võitja peab vajalikuks.</li>
              </ul>
            </li>
            <li>
              <span className="bold">Kontserdipaik</span>
              <ol>
                <li>
                  Kontsert peab toimuma võitja koduriigis. Potentsiaalse
                  kontserdipaigana saab võitja pakkuda oma korterit, maja,
                  maamaja, aeda, kodulinna lava või muud sarnast paika. Kõik
                  võimalikud kohad tuleb enne kinnitada artisti ja PepsiCo
                  tiimiga. Koha valikul tuleb arvestada mitme faktoriga.{' '}
                  <ul>
                    <li>
                      Kui kontsert toimub korteris, majas või maamajas, peab
                      võitja eelnevalt{' '}
                      <span className="bold">
                        teavitama naabreid ja saama nende nõusoleku
                      </span>{' '}
                      ning üle kontrollima: elektrisüsteemi ja selle
                      vastupidavuse; korteri suuruse; väljapääsud hädaolukorra
                      evakueerimise jaoks;
                    </li>
                    <li>
                      Kui kontsert toimub avalikul laval/ kultuurimajas, peab
                      võitja{' '}
                      <span className="bold">
                        teavitama valla-/linnavalitsust ja saama nende nõusoleku
                      </span>{' '}
                      ning üle kontrollima: konkreetse koha elektrisüsteemi ja
                      selle vastupidavuse; koha suuruse; väljapääsud
                      hädaolukorra evakueerimise jaoks;
                    </li>
                    <li>
                      Kui kontsert toimub õues/ aias, peab võitja vajadusel{' '}
                      <span className="bold">
                        teavitama naabreid ja saama nende nõusoleku
                      </span>{' '}
                      ning üle kontrollima: konkreetse õue/ aia suuruse;
                      elektrisüsteemi paigaldamise võimalikkuse; ilmastikuolud;
                    </li>
                    <li>
                      Kui kontsert toimub ühiselamu toas, peab võitja eelnevalt
                      <span className="bold">
                        {' '}
                        teavitama ühiselamu juhtkonda, naabertubade elanikke ja
                        saama nende kõigi nõusoleku
                      </span>{' '}
                      ning üle kontrollima: elektrisüsteemi ja selle
                      vastupidavuse; ühiselamu toa suuruse; turvalisuse tagamise
                      võimalused; väljapääsud hädaolukorra evakueerimise jaoks;{' '}
                    </li>
                    <li>
                      Kui peaauhinda kasutatakse sünnipäeva/ pulma kingituseks
                      või perekondliku sündmuse tähistamiseks, peab võitja üle
                      kontrollima: inimeste arvu kontserti kohas;
                      elektrisüsteemi vastupidavuse;
                    </li>
                  </ul>
                </li>
                <li>
                  Kontserdi koha valikul peab võitja arvestama, et tagatud oleks
                  esinejate ja külaliste turvalisus ning piisav ruum artistile.
                  Kohad, kuhu on raske ligipääs; on ohtlikud; pidevalt külmad/
                  kuumad; katused; mahajäetud hooned; avalikud teed või teised
                  sarnased kohad, on kontserdi korraldamiseks rangelt keelatud.{' '}
                </li>
                <li>
                  Kontserdi toimumise detailide kohta sõlmitakse võitjaga
                  leping.
                </li>
              </ol>
            </li>
            <li>
              <span className="bold">Kontserdi võimalik ärajäämine</span>
              <ol>
                <li>
                  Kontserdi ärajäämiseks võib olla mitmeid võimalikke põhjuseid.
                  Järgnevalt on reastatud mõned neist:{' '}
                  <ul>
                    <li>
                      bänd või artist jääb haigeks ning ei saa kokkulepitud ajal
                      esineda;
                    </li>
                    <li>ilm muudab kontserdi toimumise väljas võimatuks;</li>
                    <li>
                      mõni teine põhjus, mis on korraldaja kontrolli alt väljas
                      ehk mida korraldaja ei saa ise muuta või kontrollida;
                    </li>
                    <li>
                      kontserdi toimumise uueks ajaks on võimalik kokku leppida
                      uus kuupäev vastavalt punktis 9.3 äratoodud tingimustele.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Kampaanias osalejate registreerimise ja auhindade loosimise tagab
          kampaania korraldaja volitatud esindaja Direct Messenger OÜ-st, kes
          võtab ka võitjatega ühendust.
        </li>
        <li>
          <span className="bold">Isiklike andmete käsitlemine</span>
          <ol>
            <li>
              Kampaania käigus osaleja poolt esitatud isikuandmed on kaitstud ja
              PepsiCo Eesti AS töötleb neid vastavalt Eesti Vabariigis
              kehtivatele seadustele kampaania läbiviimise eesmärgil.
            </li>
            <li>
              Kampaanias osaleja annab PepsiCo Eesti AS-le nõusoleku töödelda
              esitatud andmeid järgnevatel eesmärkidel:{' '}
              <ol>
                <li>kampaania tulemuste kohta kokkuvõtete tegemiseks;</li>
                <li>
                  PepsiCo Eesti AS poolt kampaaniaga seotud koostööpartneritele
                  info edastamiseks;
                </li>
                <li>võitjate loosimiseks ja nende teavitamiseks;</li>
                <li>
                  võitja nime avaldamiseks kampaania veebilehel ja
                  sotsiaalmeedias.
                </li>
              </ol>
            </li>
            <li>
              Kampaanias osalejatel on õigus saada lisainfot PepsiCo Eesti AS
              poolt isikuandmete töötlemise kohta. Selleks tuleb saata e-kiri
              aadressile:{' '}
              <a href="mailto:pepsico.eesti@pepsico.com">
                pepsico.eesti@pepsico.com
              </a>
            </li>
            <li>
              Isikuandmete vastutav töötleja on PepsiCo Eesti AS, registrikood
              10438708, Tuglase 14, 51006 Tartu, Eesti, tel 738 6600,{' '}
              <a href="mailto:pepsico.eesti@pepsico.com">
                pepsico.eesti@pepsico.com
              </a>
            </li>
            <li>
              Kogutud andmeid säilitatakse 1 (üks) kuu alates kampaania
              lõpukuupäevast ehk kuni 22.01.2025.
            </li>
          </ol>
        </li>
        <li>
          Võitjate nimed avalikustatakse hiljemalt punktis 8.3. mainitud
          kuupäevade tööpäeva lõpuks veebilehel{' '}
          <a href="http://www.lays.ee">www.lays.ee</a>.{' '}
        </li>
        <li>
          Võitjate nimed on veebilehel üleval 2 (kaks) nädalat peale kampaania
          lõppu ehk kuni 5.01.2025.
        </li>
        <li>
          Võitjatega võtab ühendust Direct Messenger OÜ esindaja
          registreerimisvormis märgitud e-posti või telefoni teel, et leppida
          kokku auhinna üleandmise aeg.
        </li>
        <li>
          Võidetud kinkepiletid edastatakse elektrooniliselt ning krõpsukastid
          saab kätte Direct Messenger OÜ kontorist (aadressil Türi 10C, Tallinn)
          või kokkuleppel muul viisil (nt kuller või pakiautomaat). Auhinna
          kättesaamiseks peab võitja esitama oma isikut tõendava dokumendi
          (pass, ID-kaart, juhiluba) ning{' '}
          <span className="bold">registreeritud ostutšeki.</span>
          <ol>
            <li>
              Kui auhinna võitja ei esita isikut tõendavat dokumenti ja/või
              ostutšekki või kui dokumentidel toodud andmed erinevad
              registreerimisvormil toodust, ei ole võitjal õigust auhinda saada.
            </li>
            <li>
              Kui auhinna võitja ei ole veel täisealine, võib tema auhinna vastu
              võtta tema seaduslik esindaja, esitades võitja ja tema enda isikut
              tõendava dokumendi (pass, ID-kaart, juhiluba) ning võitnud
              ostutšeki.{' '}
            </li>
          </ol>
        </li>
        <li>
          Auhinna üleandmisel allkirjastavad võitja ja kampaania korraldaja
          volitatud Direct Messenger OÜ esindaja auhinna üleandmise-vastuvõtmise
          akti. Pärast akti allkirjastamist ei ole võitjal õigust kampaania
          korraldajatele auhinna suhtes nõudeid ega kaebusi esitada.
        </li>
        <li>
          Kampaania korraldajad ei vastuta auhinna võitjale auhinna kasutamise
          käigus tekkinud kahju eest.
        </li>
        <li>Auhinda ei saa vahetada teise auhinna ega raha vastu.</li>
        <li>
          Auhinna kättesaamisega seotud lisakulusid, mida ei ole käesolevates
          eeskirjades reguleeritud, võitjale ei kompenseerita.
        </li>
        <li className="bold">
          Auhind, mida võitjale välja ei anta või mille võitja jätab välja
          võtmata, loositakse uuesti välja. Kui auhinna võitjaga ei saada
          kontakti 5 tööpäeva jooksul, loositakse uus võitja.{' '}
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui kampaanias
          osalejad käesolevaid eeskirju ei järgi.
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui kampaanias
          osaleja jääb loosimisest välja, kui auhinda eeskirjade mittejärgimise
          tõttu välja ei anta, kui kampaanias osaleja esitatud andmed on väärad
          või ebatäpsed või kui võitjatega ei ole võimalik ühendust saada
          kampaania korraldajatest mittesõltuvatel põhjustel.
        </li>
        <li>
          Vääramatu jõu asjaolude esinemisel on kampaania korraldajal õigus
          kampaania ühepoolselt katkestada. Sellisel juhul on kampaania
          korraldajal kohustus teavitada Eesti Tarbijakaitseametit ja edastada
          teade kampaania peatamisest ka massimeedia kaudu.
        </li>
        <li>
          Kõik nõuded ja kaebused seoses kampaania korralduse ja/või
          läbiviimisega tuleb esitada kirjalikult kampaania korraldajale PepsiCo
          Eesti AS-le 31. jaanuariks 2025 aadressil Tuglase 14, Tartu 51006 või
          e-kirja teel aadressile{' '}
          <a href="mailto:pepsico.eesti@pepsico.com">
            pepsico.eesti@pepsico.com
          </a>
          , märkides märksõnaks „Lay’s Music kampaania 2024 “ ja esitades oma
          põhjendatud kaebuse ning lisades kaebuses viidatud dokumendid või
          nende koopiad.{' '}
          <ol>
            <li>
              Kaebusi, mis esitatakse pärast 31.01.2025, ei võeta menetlusse.
            </li>
            <li>
              Kampaania korraldaja vastab esitatud kaebusele kirjalikult 15
              päeva jooksul pärast selle kättesaamist. Kui kampaania korraldaja
              peab kaebust õigustatuks, teavitavad nad kaebuse esitajat kaebuse
              rahuldamise protsessist ja kuupäevast. Kui kaebuse esitaja ei ole
              saadud vastusega rahul, on tal õigus seadusega ettenähtud korras
              oma õigusi ja huve kaitsta.
            </li>
            <li>
              Kaebuse esitamise kuupäevaks loetakse selle postitamise kuupäeva.
            </li>
            <li>
              Kaebusele tuleb alati lisada loosis osaleja eesnimi ja
              perekonnanimi.
            </li>
          </ol>
        </li>
        <li>
          Käesolevates eeskirjades on sätestatud nii kampaania korraldaja, tema
          esindajate kui osalejate õigused ja kohustused.
        </li>
        <li>
          Kampaania korraldaja hoiatab, et reklaammaterjalides toodud teave
          kannab ainult teavitavat eesmärki.
        </li>
        <li>
          Kampaanias ei tohi osaleda PepsiCo Eesti AS, Direct Messenger OÜ ning
          Trickster Studios OÜ töötajad ja perekonnaliikmed.
        </li>
        <li>
          Kampaania korraldaja töötleb andmeid vastavalt Euroopa isikuandmete
          kaitse üldmäärusele. Lähemalt:{' '}
          <a href="https://pepsicoprivacypolicy.com/et?domain=pepsico.ee">
            https://pepsicoprivacypolicy.com/et?domain=pepsico.ee
          </a>{' '}
        </li>
      </ol>
    </div>
  );
};

export default Et;
